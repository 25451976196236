<template lang="html">
  <!-- Search -->
  <div class="toggleable-box-menu" v-bind:class="{ 'active' : $root.isSearchActive }">
    <div class="bg-menu" @click="$root.isSearchActive = false"></div>

    <b-form class="box-menu" @submit="onSubmit">
      <!-- Title -->
      <div class="box">
        <div class="row row-menu row-title-close">
          <div class="col col-sm-6 col-label">
            <h5 class="title">SEARCH <strong>PROPERTY</strong></h5>
          </div>

          <div class="col col-sm-6 col-option">
            <a class="btn-close" @click="$root.isSearchActive = false"><i class="fal fa-times"></i></a>
          </div>
        </div>
      </div>

      <!-- Filters -->
      <div class="box box-filters">
        <!-- Listing type -->
        <div class="row row-menu">
          <div class="col-sm-6 col-label">
            <h5>LISTING TYPE</h5>
          </div>

          <div class="col-sm-6 col-option">
            <div class="box-btn-filter">
              <a class="_btn" v-bind:class="{ 'active' : flOpts.type }" @click="flOpts.type = !flOpts.type">Properties for sale</a>
            </div>
          </div>

          <div class="col-12 col-collapsible">
            <b-collapse class="collapse-filter-content" v-model="flOpts.type">
              <div class="_box_">
                <b-form-radio-group class="mb-2 custom-radios-group-s1"
                  v-model="$root.searchForm.buyRent"
                  name="radios-for">
                  <b-form-radio value="1"><span>FOR SALE</span></b-form-radio>
                  <b-form-radio value="2"><span>FOR RENT</span></b-form-radio>
                </b-form-radio-group>

                <!-- <b-form-checkbox-group class="custom-checkboxes-group-s1"
                  v-model="$root.searchForm.type"
                  name="checkboxes-type">
                  <b-form-checkbox value="1">Sotheby’s Exclusive</b-form-checkbox>
                  <b-form-checkbox value="2">Open Houses</b-form-checkbox>
                  <b-form-checkbox value="3">Virtual Tour</b-form-checkbox>
                  <b-form-checkbox value="4">In Person Tour</b-form-checkbox>
                </b-form-checkbox-group> -->
              </div>
            </b-collapse>
          </div>
        </div>
        <!--  -->

        <!-- Price -->
        <div class="row row-menu">
          <div class="col-sm-4 col-label">
            <h5>PRICE</h5>
          </div>

          <div class="col-sm-8 col-option">
            <div class="box-btn-filter">
              <a class="_btn" v-bind:class="{ 'active' : flOpts.price }" @click="flOpts.price = !flOpts.price">
                <span v-if="(minVal($root.searchForm.price[0], 10000)) && (maxVal($root.searchForm.price[1], 20000000))">Any</span>
                <span v-else>
                  $ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format($root.searchForm.price[0]) }} <small v-if="$root.currency == 'usd'">USD</small><small v-if="$root.currency == 'mxn'">MXN</small> - $ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format($root.searchForm.price[1]) }} <small v-if="$root.currency == 'usd'">USD</small><small v-if="$root.currency == 'mxn'">MXN</small>
                </span>
              </a>
            </div>
          </div>

          <div class="col-12 col-collapsible" v-if="$root.searchForm.buyRent == 1">
            <b-collapse class="collapse-filter-content" v-model="flOpts.price">
              <div class="_box_" v-if="flOpts.pricePlugin">
                <div class="box-range">
                  <vue-slider :tooltip="false" ref="slider" :height="2" :interval="10000" :min="10000" :max="20000000" v-model="$root.searchForm.price"></vue-slider>

                  <div class="range-info">
                    <div class="min">
                      <span v-if="minVal($root.searchForm.price[0], 10000)">No min</span> <span v-else>Min: $ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format($root.searchForm.price[0]) }} <small v-if="$root.currency == 'usd'">USD</small><small v-if="$root.currency == 'mxn'">MXN</small> </span>
                    </div>
                    <div class="max">
                      <span v-if="maxVal($root.searchForm.price[1], 20000000)">No Max</span> <span v-else>Max: $ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format($root.searchForm.price[1]) }} <small v-if="$root.currency == 'usd'">USD</small><small v-if="$root.currency == 'mxn'">MXN</small></span>
                    </div>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>
          <div class="col-12 col-collapsible" v-else-if="$root.searchForm.buyRent == 2">
            <b-collapse class="collapse-filter-content" v-model="flOpts.price">
              <div class="_box_" v-if="flOpts.pricePlugin">
                <div class="box-range">
                  <vue-slider :tooltip="false" ref="slider" :height="2" :interval="100" :min="1500" :max="200000" v-model="$root.searchForm.price"></vue-slider>

                  <div class="range-info">
                    <div class="min">
                      <span v-if="minVal($root.searchForm.price[0], 1500)">No min</span> <span v-else>Min: $ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format($root.searchForm.price[0]) }} <small v-if="$root.currency == 'usd'">USD</small><small v-if="$root.currency == 'mxn'">MXN</small> </span>
                    </div>
                    <div class="max">
                      <span v-if="maxVal($root.searchForm.price[1], 200000)">No Max</span> <span v-else>Max: $ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format($root.searchForm.price[1]) }} <small v-if="$root.currency == 'usd'">USD</small><small v-if="$root.currency == 'mxn'">MXN</small></span>
                    </div>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>
        </div>
        <!--  -->

        <!-- Location -->
        <!-- <div class="row row-menu">
          <div class="col-sm-6 col-label">
            <h5>LOCATION</h5>
          </div>

          <div class="col-sm-6 col-option">
            <div class="box-select">
              <b-form-select v-model="$root.searchForm.location">
                <b-form-select-option :value="null">Any</b-form-select-option>
                <b-form-select-option :value="loc.value" v-for="(loc, loInx) in $root.locationProps" :key="'loInx-'+loInx">{{ loc.text }}</b-form-select-option>
              </b-form-select>
            </div>
          </div>
        </div> -->
        <!--  -->

        <!-- Property type -->
        <div class="row row-menu">
          <div class="col-sm-6 col-label">
            <h5>PROPERTY TYPE</h5>
          </div>

          <div class="col-sm-6 col-option">
            <div class="box-select">
              <b-form-select v-model="$root.searchForm.propType">
                <b-form-select-option :value="null">Any</b-form-select-option>
                <b-form-select-option :value="prop.value" v-for="(prop, ptInx) in $root.propTypeOpts" :key="'ptInx-'+ptInx">{{ prop.text }}</b-form-select-option>
              </b-form-select>
            </div>
          </div>
        </div>
        <!--  -->

        <!-- Acreage -->
        <!-- <div class="row row-menu">
          <div class="col-sm-6 col-label">
            <h5>ACREAGE</h5>
          </div>

          <div class="col-sm-6 col-option">
            <div class="box-btn-filter">
              <a class="_btn" v-bind:class="{ 'active' : flOpts.acreage }" @click="flOpts.acreage = !flOpts.acreage">
                <span v-if="(minVal($root.searchForm.acreage[0], 0)) && (maxVal($root.searchForm.acreage[1], 90))">Any</span>
                <span v-else>
                  {{ $root.searchForm.acreage[0] }} - {{ $root.searchForm.acreage[1] }}
                </span>
              </a>
            </div>
          </div>

          <div class="col-12 col-collapsible">
            <b-collapse class="collapse-filter-content" v-model="flOpts.acreage">
              <div class="_box_" v-if="flOpts.acreagePlugin">
                <div class="box-range">
                  <vue-slider :tooltip="false" ref="slider" :height="2" :interval="5" :min="0" :max="90" v-model="$root.searchForm.acreage"></vue-slider>

                  <div class="range-info">
                    <div class="min">
                      <span v-if="minVal($root.searchForm.acreage[0], 0)">No min</span> <span v-else>Min: {{ $root.searchForm.acreage[0] }}</span>
                    </div>
                    <div class="max">
                      <span v-if="maxVal($root.searchForm.acreage[1], 90)">No Max</span> <span v-else>Max: {{ $root.searchForm.acreage[1] }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>
        </div> -->
        <!--  -->

        <!-- Lifestyles -->
        <div class="row row-menu">
          <div class="col-sm-6 col-label">
            <h5>LIFESTYLES</h5>
          </div>

          <div class="col-sm-6 col-option">
            <div class="box-select">
              <b-form-select v-model="$root.searchForm.lifestyle">
                <b-form-select-option :value="null">Any</b-form-select-option>
                <b-form-select-option :value="prop.id" v-for="(prop, ptInx) in $root.lifestyles" :key="'ptasInx-'+ptInx">{{ prop.name }}</b-form-select-option>
              </b-form-select>
            </div>
          </div>
        </div>
        <!--  -->
      </div>

      <!-- Form Buttons -->
      <div class="box-bottom">
        <b-button type="button" class="btn-s1 white btn-outline">Clear all</b-button>
        <b-button type="submit" class="btn-s1 white btn-bg">Apply</b-button>
      </div>
    </b-form>
  </div>
  <!--  -->
</template>

<script>
import vueSlider from 'vue-slider-component'; // https://github.com/NightCatSama/vue-slider-component/tree/v2 (para vue 2)
export default {
  components: {
    vueSlider
  },

  data() {
    return {
      flOpts: { // Object usado para algunos filtros que requieren "toggle"
        type: false,

        price: false,
        pricePlugin: false,

        size: false,

        acreage: false,
        acreagePlugin: false,
      },

      form: {

      },
    }
  },

  methods: {
    minVal(value, min) {
      if( typeof(value) == 'undefined' || value == min) {
        return true;
      }
    },

    maxVal(value, max) {
      if( typeof(value) == 'undefined' || value == max) {
        return true;
      }
    },

    onSubmit(event) {
      event.preventDefault();
      this.$router.push({ name: 'propsPage', query: { buyrent: this.$root.searchForm.buyRent,
                                                        location: this.$root.searchForm.location,
                                                        propType: this.$root.searchForm.propType,
                                                        minprice: this.$root.searchForm.price[0],
                                                        maxprice: this.$root.searchForm.price[1],
                                                        minacreage: this.$root.searchForm.acreage[0],
                                                        maxminacreage: this.$root.searchForm.acreage[1],
                                                        lifestyle: this.$root.searchForm.lifestyle,

                                                      } });
    },
  },

  watch: {
    'flOpts.price'(newValue, oldValue) {
      setTimeout(() => {
        this.flOpts.pricePlugin = (newValue) ? true : false;
      }, 300);
    },

    'flOpts.acreage'(newValue, oldValue) {
      setTimeout(() => {
        this.flOpts.acreagePlugin = (newValue) ? true : false;
      }, 300);
    },

    '$route' (to, from){
      this.$root.isSearchActive = false;
    }
  },
}
</script>
