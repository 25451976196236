<template lang="html">
  <div id="agents-page">

    <section class="placed-backg banner-section-s1" v-bind:style="{ backgroundImage: 'url(public/images/pages/contact/bg.png)' }">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-lg-6 col-xl-5 col-titles">
            <h1 class="mb-3 title">{{ $t("agents.description") }}</h1>

            <!-- <h6 class="title-sl-1"><span>{{ $t("agents.title") }}</span></h6> -->
          </div>

          <div class="col-lg-5 col-xl-4 col-info">
            <!-- <p>{{ $t("agents.description") }} </p> -->
          </div>

          <div class="col-lg-1 col-xl-2 col-bg">
            <div class="bg-color"></div>
          </div>
        </div>
      </div>
    </section>

    <section class="container oversized-container agents-info-section">
      <div class="row">
        <div class="col-12 col-search">
          <b-form @submit="onSubmit">
            <b-button class="btn-search" type="submit"><i class="fal fa-search"></i></b-button>
            <b-form-group class="custom-f-group-2">
              <b-form-input
                v-model="formSearch.keywords"
                type="text"
                :placeholder='$t("agents.searchbyname")'
                
              ></b-form-input>
            </b-form-group>
          </b-form>
        </div>

        <div class="col-lg-3 col-short-info">
          <h2 class="title">{{ $t("agents.agentsserving") }}</h2>

          <h6 class="results">{{pagination.total_agents}} {{ $t("agents.results") }}</h6>

          <hr class="style-1">

          <h6 class="mb-3 subtitle gray">{{ $t("agents.connect") }}</h6>
          <h6 class="blue subtitle"><router-link to="/contact" style="color:#002349"> {{ $t("agents.findyouroffice") }} <i class="far fa-long-arrow-right"></i></router-link></h6>
        </div>

        <div class="col-lg-9 col-agents-info">
          <div class="row">
            <div class="col-12 col-top">
              <div class="col col-title">
                <h5>{{ $t("agents.associate") }}</h5>
              </div>

              <div class="col col-filter">
                <span>{{ $t("agents.filterby") }}</span>
                <b-form-select v-model="formSearch.order">
                  <b-form-select-option value="asc">A {{ $t("agents.filterorder") }} Z</b-form-select-option>
                  <b-form-select-option value="desc">Z {{ $t("agents.filterorder") }} A</b-form-select-option>
                </b-form-select>
              </div>
            </div>

            <div class="col-12 col-agents">
              <div class="box-agent-sample-1" v-for="(a, aInx) in agents" :key="'aInx-'+aInx">
                <div class="col col-photo">
                  <router-link class="placed-backg photo" :to="'/agents/'+a.id" v-bind:style="{ backgroundImage: 'url('+ a.imageUrl +')' }" style="background-position:top">
                    <img src="public/images/pages/agents/agent.png">
                  </router-link>
                </div>

                <div class="col col-info">
                  <h5 class="name"><router-link :to="'/agents/'+a.id">{{a.name}}</router-link></h5>

                  <!-- <h6 class="pos">{{a.profession}}</h6> -->
                  <h6 class="pos">{{ $t("agents.luxury") }}</h6>

                  

                  <hr class="style-1" />

                  <h6 class="mb-1 contact">{{ $t("agents.phone") }}: <a style="text-decoration: none;color: #010202;" :href="'tel:'+a.phone">{{a.phone}}</a></h6>
                  <!-- <h6 class="contact">Email: {{a.email}}</h6> -->
                  <h6 class="contact">{{ $t("agents.email") }}: <a style="text-decoration: none;color: #010202;" :href="'mailto:info@cdmxsir.com'">info@cdmxsir.com</a></h6>


                  
                </div>

                <div class="col col-extra-info">
                  <h6 class="mb-2 office">{{ $t("agents.office") }}</h6>
                  <h6 class="mb-3 mb-sm-5 location">{{a.address}}</h6>

                  <router-link class="btn-msg" :to="'/agents/'+a.id">{{ $t("agents.sendmessage") }} <i class="far fa-long-arrow-right"></i></router-link>
                </div>
              </div>
              <br>
              <div class="row" v-if="agents.length > 0">
                  <div class="col-12 text-center">
                    <div class="d-inline-block mx-0 col-pagination-sample-1">
                      <b-pagination-nav :link-gen="linkGen" :number-of-pages="pagination.total" use-router></b-pagination-nav>
                    </div>
                  </div>
              </div>

            </div>

            
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      agents: [],
      formSearch: {
        keywords: null,
        order: 'asc',
      },
      pagination:{
        currentpage:1,
        total:1,
        number: 10,
        total_agents:1,
      },
    }
  },
  watch:{
      'formSearch.order':function(){
          this.getAgents();
      },
      '$route.query.page':function(val){
        if (val) {
            this.pagination.currentpage = val;
        }
        else{
            this.pagination.currentpage = 1;
        }
        this.getAgents();
        
    },
  },
  methods: {
    getAgents() { // Obtener la info de la BD
      this.$root.modal_loading = true;
      axios.post(tools.url("/api/agents?page=" + this.pagination.currentpage),this.formSearch).then((response)=>{
        this.agents = response.data.data;
        this.pagination.total = response.data.last_page;
        this.pagination.total_agents = response.data.total;
        this.$root.modal_loading = false;
      });
    },
    onSubmit(event) {
      event.preventDefault();
      this.getAgents();
    },
    linkGen(pageNum) {
         return pageNum === 1 ? '?' : `?page=${pageNum}`
    },
  },
  //insert the following code for vue-meta to work
  metaInfo() {
    return {
      meta: [
        { name: 'og:title', content: this.$root.seoadata.agents.meta_title},
        { name: 'og:description', content: this.$root.seoadata.agents.meta_description	},
        { name: 'og:keywords', content: this.$root.seoadata.agents.meta_keywords},
      ]
    }
  },
  mounted(){
    this.getAgents();
  },
}
</script>

