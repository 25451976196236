<template lang="html">
  <div id="article-page">

    <section class="placed-backg banner-section-s1" v-bind:style="{ backgroundImage: 'url(public/images/pages/contact/bg.png)' }">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-lg-6 col-xl-5 col-titles">
            <h1 class="mb-3 title">{{ $t("blog.title") }}</h1>

            <h6 class="title-sl-1"><span>{{ $t("blog.subtitle") }}</span></h6>
          </div>

          <div class="col-lg-5 col-xl-4 col-info">
            <p></p>
          </div>

          <div class="col-lg-1 col-xl-2 col-bg">
            <div class="bg-color"></div>
          </div>
        </div>
      </div>
    </section>

    <section class="container article-content-section">
      <div class="row">
        <div class="col-12 col-top">
          <p class="mb-2 mb-sm-3">
            <router-link class="btn-back" to="/blog">{{ $t("blog.detail.back") }}</router-link>
          </p>

          <h1 class="mb-2 title" v-if="$i18n.locale == 'es'">{{blog.title_es}}</h1>
          <h1 class="mb-2 title" v-if="$i18n.locale == 'en'">{{blog.title}}</h1>

          <h5 class="by-n-date">{{ $t("blog.detail.by_sothebys") }} <span class="sep">|</span> {{blog.created}}</h5>
        </div>

        <div class="col-12 col-image">
          <div  v-if="$i18n.locale == 'es'" class="placed-backg image" v-bind:style="{ backgroundImage: 'url('+ blog.imageUrl_es +')' }">
            <img src="public/images/pages/blog/image.png">
          </div>
          <div  v-if="$i18n.locale == 'en'" class="placed-backg image" v-bind:style="{ backgroundImage: 'url('+ blog.imageUrl +')' }">
            <img src="public/images/pages/blog/image.png">
          </div>
        </div>

        <div class="col-12 col-content" v-html="blog.body_es" v-if="$i18n.locale == 'es'"></div>
        <div class="col-12 col-content" v-html="blog.body" v-if="$i18n.locale == 'en'"></div>
        
      </div>
    </section>

  </div>
</template>


<script>
export default {
  data() {
    return {

      blog:{},
      id:null
      // == ==
    }
  },
  methods: {
    getBlog() { // Obtener la info de la BD
      this.$root.modal_loading = true;
      axios.get(tools.url("/api/blogs/"+this.id)).then((response)=>{
        this.blog = response.data;
        this.$root.modal_loading = false;
      });
    },
  },
  //insert the following code for vue-meta to work
  metaInfo() {
    return {
      meta: [
        { name: 'og:title', content: this.blog.meta_title},
        { name: 'og:description', content: this.blog.meta_description	},
        { name: 'og:keywords', content: this.blog.meta_keywords},
      ]
    }
  },
  mounted(){
    if(this.$route.params.id){
      this.id = this.$root._getURLID(this.$route.params.id);
        this.getBlog();
    }
  },
}
</script>

<style>
.col-content img{
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>