<template lang="html">
  <div id="blog-page">

    <section class="placed-backg banner-section-s1" v-bind:style="{ backgroundImage: 'url(public/images/pages/contact/bg.png)' }">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-lg-6 col-xl-5 col-titles">
            <h1 class="mb-3 title">{{ $t("blog.title") }}</h1>

            <h6 class="title-sl-1"><span>{{ $t("blog.subtitle") }}</span></h6>
          </div>

          <div class="col-lg-5 col-xl-4 col-info">
            <p></p>
          </div>

          <div class="col-lg-1 col-xl-2 col-bg">
            <div class="bg-color"></div>
          </div>
        </div>
      </div>
    </section>

    <section class="container oversized-container articles-section">
      <div class="row">
        <div class="col-md-6 col-lg-4 col-article-sample-1" v-for="(a, bInx) in blogs" :key="'bInx-'+bInx">
          <router-link class="box-link" :to="'/blog/'+$root._converToURL(a.title, a.id)">
            <div class="box-image">
              <div class="t-550 more">{{ $t("blog.readmore") }}</div>
              <div v-if="$i18n.locale == 'es'" class="placed-backg img-fake" v-bind:style="{ backgroundImage: 'url(' + a.imageUrl_es+ ')' }"></div>
              <div v-if="$i18n.locale == 'en'" class="placed-backg img-fake" v-bind:style="{ backgroundImage: 'url(' + a.imageUrl+ ')' }"></div>
              <img src="public/images/pages/blog/article.png">
            </div>

            <div class="box-text">
              <h5 class="title" v-if="$i18n.locale == 'es'">{{a.title_es}}</h5>
              <h5 class="title" v-if="$i18n.locale == 'en'">{{a.title}}</h5>
              <h6 class="date">{{a.created}}</h6>
            </div>
          </router-link>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      blogs: [
        /*{ id: 1, imageUrl: 'public/images/pages/blog/article-1.jpg', title: 'Article title in the blog', created: '6/04/2022' },
        { id: 2, imageUrl: 'public/images/pages/blog/article-2.jpg', title: 'Article title in the blog', created: '6/04/2022' },
        { id: 3, imageUrl: 'public/images/pages/blog/article-3.jpg', title: 'Article title in the blog', created: '6/04/2022' },

        { id: 4, imageUrl: 'public/images/pages/blog/article-3.jpg', title: 'Article title in the blog', created: '6/04/2022' },
        { id: 5, imageUrl: 'public/images/pages/blog/article-2.jpg', title: 'Article title in the blog', created: '6/04/2022' },
        { id: 6, imageUrl: 'public/images/pages/blog/article-1.jpg', title: 'Article title in the blog', created: '6/04/2022' },
        */
      ]
    }
  },
  methods: {
    getBlogs() { // Obtener la info de la BD
      this.$root.modal_loading = true;
      axios.get(tools.url("/api/blogs")).then((response)=>{
        this.blogs = response.data;
        this.$root.modal_loading = false;
      });
    },
  },
  //insert the following code for vue-meta to work
  metaInfo() {
    return {
      meta: [
        { name: 'og:title', content: this.$root.seoadata.blogs.meta_title},
        { name: 'og:description', content: this.$root.seoadata.blogs.meta_description	},
        { name: 'og:keywords', content: this.$root.seoadata.blogs.meta_keywords},
      ]
    }
  },
  mounted(){
    this.getBlogs();
  },
}
</script>

