<template lang="html">
    <div id="contact-page">

      <section class="container oversized-container form-section" style="text-align: center;padding-top: 100px;padding-bottom: 100px;">
            <img src="/public/images/pages/contact/check-circle.png">
            <br><br><br>
            <h2 class="title" style="font-size: 1.1rem;">{{ $t("contact.thankyou") }}</h2>
      </section>
  
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
       
      }
    },
  
    methods: {

      
    },
     //insert the following code for vue-meta to work
     metaInfo() {
      return {
        meta: [
          { name: 'og:title', content: this.$root.seoadata.cotactus.meta_title},
          { name: 'og:description', content: this.$root.seoadata.cotactus.meta_description	},
          { name: 'og:keywords', content: this.$root.seoadata.cotactus.meta_keywords},
        ]
      }
    },
  }
  </script>
  