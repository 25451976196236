<template lang="html">
  <div id="about-us-2-page">

    <section class="info-1-section" >
      <div class="container">
        <div class="box-intro">
          <h1 class="title">Privacy Notice</h1>
          
        </div>

       
      </div>
    </section>

    <section class="container info-2-section">
      <div class="row row-1" v-if="$i18n.locale == 'en'" ><div class="col-lg-12 text-justify pt-0 box-1-info" >
          <p>The policy set forth below is provided in English only to avoid any misinterpretations caused by a language translation. If necessary, please seek the proper advice, or please do not use this web site.</p>
          <p><br></p>
          <p>Effective Date: January 1, 2023</p>
          <p><br></p>
          <p>Sotheby’s International Realty® Online Privacy Statement</p>
          <p><br></p>
          <p>Sotheby’s International Realty Affiliates LLC (“SIRA”) and our affiliate and subsidiary companies (such as Anywhere Real Estate Inc., Guaranteed Rate Affinity, Anywhere Integrated Services, Anywhere Advisors, Realogy Insurance Agency, and Anywhere Leads Inc.) (collectively, “SIRA” “we,” “our,” or “us”) are committed to privacy and to transparency in our information practices. This Privacy Policy describes how we collect, use, disclose, and otherwise process the personal information described in this Privacy Policy, as well as the rights and choices individuals have regarding such personal information. This Privacy Policy applies to the extent we process personal data on our own behalf, as a controller or business.</p>
          <p><br></p>
          <p>If you are a California resident, please be sure to review Section 17B. California Residents for important information about the categories of personal information we collect and disclose as well as your rights under California privacy laws.</p>
          <p><br></p>
          <p>You can click the links below to go directly to a particular section in our Privacy Policy.</p>
          <p><br></p>
          <p>SCOPE OF THIS PRIVACY POLICY</p>
          <p>OUR COLLECTION OF PERSONAL INFORMATION</p>
          <p>PURPOSES AND LEGAL BASIS FOR OUR PROCESSING</p>
          <p>DISCLOSURE OF PERSONAL INFORMATION</p>
          <p>AGGREGATE AND NON-IDENTIFIABLE DATA</p>
          <p>COOKIES, TRACKING, AND ANALYTICS</p>
          <p>INTEREST-BASED ADVERTISING</p>
          <p>SOCIAL FEATURES AND THIRD-PARTY PLUG INS</p>
          <p>INTERNATIONAL TRANSFERS OF PERSONAL INFORMATION</p>
          <p>SECURITY</p>
          <p>DATA RETENTION</p>
          <p>YOUR RIGHTS AND CHOICES</p>
          <p>CHILDREN</p>
          <p>THIRD-PARTY WEBSITES</p>
          <p>CHANGES TO THIS PRIVACY POLICY</p>
          <p>CONTACT US</p>
          <p>ADDITIONAL INFORMATION FOR CERTAIN JURISDICTIONS</p>
          <p>EEA and the United Kingdom</p>
          <p>California Residents.</p>
          <p>Residents of Other U.S. States</p>
          <p><br></p>
          <p>1. SCOPE OF THIS PRIVACY POLICY</p>
          <p><br></p>
          <p>This Privacy Policy applies to our collection, use, disclosure, and other processing of personal information related to:</p>
          <p><br></p>
          <p>Our websites (each, a “Website ”) and mobile applications (each, an “ App ”) that display or link to this Privacy Policy and our other services, products, and technology solutions (collectively, the “ Services ”).</p>
          <p>Former, current, and prospective clients, brokers, independent agents, and customers.</p>
          <p>Other individuals who use our Services, whose personal information we receive related to the Services, or who otherwise interact or communicate with us related to our Services or our business.</p>
          <p>Our collection, use, disclosure, and processing of personal information about individuals will vary depending upon the circumstances. This Privacy Policy is intended to describe our overall privacy and data protection practices.</p>
          <p><br></p>
          <p>Not covered by this Privacy Policy. This Privacy Policy does not apply to our employees and non-employee workers.</p>
          <p><br></p>
          <p>Additional privacy notices. In some cases, different or additional notices about our data collection and processing practices may be provided and apply to our processing of certain personal information. The additional notice will control to the extent there is a conflict with this Privacy Policy, with respect to your personal information that is subject to that notice.</p>
          <p><br></p>
          <p>2. OUR COLLECTION OF PERSONAL INFORMATION</p>
          <p><br></p>
          <p>Generally, we collect your personal information on a voluntary basis. However, if you decline to provide certain personal information that is marked mandatory, you may not be able to access certain Services or we may be unable to fully respond to your inquiry.</p>
          <p><br></p>
          <p>We may collect personal information directly from individuals, automatically related to the use of the Services, and in some cases, from third parties (such as social networks, platform providers, payment processors, data providers, and operators of certain third- party services that we use). In some cases (such as where required by law), we ask for your consent or give you certain choices prior to collecting or using certain personal information.</p>
          <p><br></p>
          <p>Information we collect directly. We collect information that you provide to us, such as:</p>
          <p><br></p>
          <p>When you create an account, provide contact information, or send other personal information to us.</p>
          <p>When you complete or submit a form through our Services, for example, “Contact Us” or “More Information” forms.</p>
          <p>When you participate in surveys or contests, submit orders or requests, or register for or use our Services, or otherwise contact us.</p>
          <p>Information you submit or provide such as real estate searches and preferences, whether you plan on buying or selling real estate, home search criteria, information about financing for the purchase of real estate, and other information related to the purchase or sale of real estate.</p>
          <p>Information we collect from third parties. We may collect or receive personal information about you and your property from third party sources, such as public databases, joint marketing partners, social media platforms (including from people with whom you are friends or otherwise connected), brokers and affiliated agents, and other real estate professionals, and third parties. For example:</p>
          <p><br></p>
          <p>Social media. If you choose to link, create, or log in to your Services account with a social media service (e.g., Twitter, Facebook, Instagram, etc.), or if you engage with a separate app or website that communicates with the Services, we may receive personal information about you or your connections from that service. In many cases you can control what personal information you share with us through privacy settings available on those third-party social media services.</p>
          <p>Real estate professionals. If you interact or list a property with a broker, independent agent, or other real estate professional associated with an Anywhere brand, we may receive personal information about you and your property from them, such as your name and property address, listing and contact information, among other things. These real estate professionals may be affiliated with independently owned and operated brokerages, or with an Anywhere company.</p>
          <p>Property records. We may collect information about individuals and real estate property through public databases, such as property ownership and tax records maintained by state, local and county agencies.</p>
          <p><br></p>
          <p>3. PURPOSES AND LEGAL BASIS FOR OUR PROCESSING</p>
          <p><br></p>
          <p>Certain laws, including the EU General Data Protection Regulation (“GDPR”), UK and Brazil data protection law, and similar laws require that we inform applicable individuals of the legal basis for our use and other processing of their personal information. In this section, we described the purposes for which will collect, use, disclose and otherwise process personal information, as well as the legal bases for such processing, generally, under such privacy laws.</p>
          <p><br></p>
          <p>Legal bases under certain privacy laws. Pursuant to the GDPR, UK and Brazil data protection law, and similar laws we collect, use and otherwise process personal information for the following legal bases:</p>
          <p><br></p>
          <p>Performance of contract: as necessary to enter into or carry out the performance of our contract with you.</p>
          <p>Compliance with laws: for compliance with legal obligations and/or defense against legal claims, including those in the area of labor and employment law, social security, data protection, tax, and corporate compliance laws.</p>
          <p>Our legitimate interests: in furtherance of our legitimate business interests including, for example:</p>
          <p>Performance of contracts with franchisees and other parties</p>
          <p>Implementation and operation of global support (e.g., IT) services for our business operations</p>
          <p>Customer relationship management and improving our Services, including marketing and analytics</p>
          <p>Marketing and advertising</p>
          <p>Fraud prevention, including misuse of company IT systems or money laundering</p>
          <p>Physical, IT, and network perimeter security</p>
          <p>Internal investigations</p>
          <p>Mergers, acquisitions, and reorganization, and other business transactions</p>
          <p>With your consent: in some cases, we rely on your consent to collect and process your personal information. (See Section 17A below for information about withdrawing your consent.)</p>
          <p>In addition, we may process your personal information where necessary to protect the vital interests of any individual.</p>
          <p><br></p>
          <p>Purposes of processing. While the purposes for which we may process personal information will vary depending upon the circumstances, in general we collect, use, disclose and otherwise process personal information for the purposes set forth below, as well as the legal basis for such processing, as applicable under relevant laws (see above for further explanation of these legal bases):</p>
          <p><br></p>
          <p>Providing support and services: including, for example, to send or facilitate communications among you, independent real estate professionals and our affiliated entities, and other users of the Services, provide products and services you request (and send related information), operate our Services; to communicate with you about your access to and use of our Services; to respond to your inquiries; to provide troubleshooting, fulfill your requests and provide technical support; and for other customer service and support purposes. (Legal basis: performance of our contract with you; and our legitimate interests)</p>
          <p>Analyzing and improving our business: including to better understand how users access and use our Services, to evaluate and improve our Services and business operations, and to develop new features, offerings, and services; to conduct surveys, and other evaluations, such as customer satisfaction surveys; and for other research and analytical purposes. (Legal basis: our legitimate interests)</p>
          <p>Personalizing content and experiences: including to provide or recommend features, content, social connections, and referrals; tailor content we send or display on our Services; to offer location customization and personalized help and instructions; and to otherwise personalize your experiences. (Legal basis: our legitimate interests and/or with your consent)</p>
          <p>Advertising, marketing and promotional purposes: including to reach you with more relevant ads and to evaluate, measure, and improve the effectiveness of our ad campaigns; to send you newsletters, offers, or other information we think may interest you; to contact you about our Services or information we think may interest you; and to administer promotions and contests. (Legal basis: our legitimate interests and/or with your consent)</p>
          <p>Securing and protecting our business: including to protect and secure our business operations, assets, Services, network and information and technology resources; to investigate, prevent, detect and take action regarding fraud, unauthorized access, situations involving potential threats to the rights or safety of any person or third party, or other unauthorized activities or misconduct. (Legal basis: our legitimate interests and/or compliance with laws)</p>
          <p>Defending our legal rights: including to manage and respond to actual and potential legal disputes and claims, and to otherwise establish, defend or protect our rights or interests, including in the context of anticipated or actual litigation with third parties. (Legal basis: our legitimate interests and/or compliance with laws)</p>
          <p>Planning and facilitating business transactions: related to any actual or contemplated merger, acquisition, asset sale or transfer, financing, bankruptcy or restructuring of all or part of our business. (Legal basis: our legitimate interests and/or compliance with laws)</p>
          <p>Auditing, reporting, corporate governance, and internal operations: including relating to financial, tax and accounting audits; audits and assessments of our operations, privacy, securityand financial controls, risk, and compliance with legal obligations; and our general business, accounting, record keeping and legal functions. (Legal basis: our legitimate interests and/or compliance with laws)</p>
          <p>Complying with legal obligations: including to comply with the law, our legal obligations and legal process, such warrants, subpoenas, court orders, and regulatory or law enforcement requests. (Legal basis: our legitimate interests and/or compliance with laws)</p>
          <p><br></p>
          <p>4. DISCLOSURE OF PERSONAL INFORMATION</p>
          <p><br></p>
          <p>We may disclose the personal information we collect to third parties, as reasonably necessary for the purposes described above and as otherwise directed or consented to by you, including:</p>
          <p><br></p>
          <p>Service providers to third-party service providers who use this information to perform services for us, such as hosting providers, auditors, advisors, consultants, and customer service and support providers.</p>
          <p>Subsidiaries and affiliates: to our subsidiary and affiliated companies (i.e., companies under common ownership, control or management with us), such as Anywhere Real Estate Inc., Guaranteed Rate Affinity, Anywhere Integrated Services, Anywhere Advisors, Realogy Insurance Agency, and Anywhere Leads Inc., who may process your personal information in accordance with the principles of this Privacy Policy, including to share relevant news, information and offers with you. Where required by applicable law, we will obtain your consent to such sharing.</p>
          <p>Third parties: to third parties in order to provide our Services, respond to or carry out your requests, and/or with your consent, including to:</p>
          <p>Independent real estate professionals and owners and operators of real estate brokerage companies to enable them to provide services you request through the Services.</p>
          <p>Third parties to provide you a service you requested through a partnership or promotional offering made by a third party or us.</p>
          <p>The public if you submit to a public part of the Services, such as comments, social media posts, or other features viewable by the public or generally by registered users of the Services.</p>
          <p>Third parties with whom you choose to let us share information, for example other apps or websites that integrate with the Services, or those with Services with which we integrate.</p>
          <p>Third parties we work with to provide you with more personalized content and ads, and to track the effectiveness of campaigns.</p>
          <p>Business transfers: as part of any actual or contemplated merger, sale, and transfer of our assets, acquisition, financing or restructuring of all or part of our business, bankruptcy or similar event; and prior to the completion of such a transfer, where necessary for due diligence or to plan for the transfer, such as to lenders, auditors, and third-party advisors, such attorneys and consultants, where permitted by law.</p>
          <p>Legally required: where we are required to do so by law or legal process (e.g., to law enforcement, courts or others, or in response to a subpoena or court order).</p>
          <p>Protect our rights: where we believe it necessary to respond to claims asserted against us or, comply with legal process (e.g., subpoenas or warrants), enforce or administer our agreements and terms, for fraud prevention, risk assessment, investigation, and to protect the rights, property, or safety of us, our clients and customers or others. including personal information, related to litigation and other legal claims or proceedings in which we are involved, as well as for our internal accounting, auditing, compliance, recordkeeping, and legal functions.</p>
          <p><br></p>
          <p>5. Aggregate and Non-identifiable Data</p>
          <p><br></p>
          <p>We may also use and disclose aggregate, anonymous, and other non-identifiable data related to our business and the Services for quality control, analytics, research, development and other purposes. Where we use, disclose or process de-identified data (data that is no longer reasonably linked or linkable to an identified or identifiable natural person, household, or personal or household device) we will maintain and use the information in deidentified form and not to attempt to reidentify the information, except as permitted by applicable privacy laws (such as to confirm whether our deidentification processes are reasonable and adequate).</p>
          <p><br></p>
          <p><br></p>
          <p>6. COOKIES, TRACKING, AND ANALYTICS</p>
          <p><br></p>
          <p>Our Services may use first party and third-party cookies, pixel tags, plugins, and other tools to gather device, usage and browsing information when users visit our Services. For instance, when you visit our Services, our server may record your IP address (and associated location information) and other information such as the type of your internet browser, your Media Access Control (MAC) address, computer type (Windows or Macintosh), screen resolution, operating system name and version, device manufacturer and model, language, and the pages you view and links you select on the Services, as well as date and time stamps associated with your usage of and activities on our Services.</p>
          <p><br></p>
          <p>We use the information for security purposes, to facilitate navigation, to improve, analyze and optimize our Services and their functionality, to personalize and improve your experience while using the Services, to improve and measure our advertising campaigns, and to better reach users with relevant advertising both on our Services and on third party websites.</p>
          <p><br></p>
          <p>Click “Cookie Preferences” link in the footer of our Website for more information about cookies on that Website, and to review and update your preferences for most types of cookies (other than those that are necessary to the functionality of that Website).</p>
          <p><br></p>
          <p>Cookies. Cookies are small text files that a website transfers to your hard drive to store and sometimes collect information about your usage of websites, such as time spent on the websites, pages visited, language preferences, and other anonymous traffic data. You can control the way in which cookies are used by altering your browser settings. You may refuse to accept cookies by activating the setting on your browser that allows you to reject cookies. However, if you select such a setting, this may affect the functioning of our Services. Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue cookies when you access or log on to our Service.</p>
          <p><br></p>
          <p>If you have disabled one or more cookies, we may still use information collected from cookies before your disabled preference was set. However, we will stop using the disabled cookie to collect any further information.</p>
          <p><br></p>
          <p>Pixel tags and other similar technologies. Pixel tags (also known as web beacons and clear GIFs) may be used in connection with some Websites to, among other things, track the actions of users of the Websites (including email recipients), measure the success of our marketing campaigns and compile statistics about usage of the Websites and response rates. We and our service providers may also use pixel tags in HTML emails to our customers, to help us track email response rates, identify when our emails are viewed, track whether our emails are forwarded, and conduct analytics.</p>
          <p><br></p>
          <p>Log files. Most browsers collect certain information, such as your IP address, device type, screen resolution, operating system version and internet browser type and version. This information is gathered automatically and stored in log files.</p>
          <p><br></p>
          <p>Third party analytics tools. Our Websites use automated devices and applications operated by third parties, such as Google Analytics, which uses cookies and similar technologies to collect and analyze information about use of the Websites and report on activities and trends. This service may also collect information regarding the use of other websites, apps and online resources. You can learn about Google’s practices and opt-out of collection of your browsing data by Google Analytics at https://tools.google.com/dlpage/gaoptout.</p>
          <p><br></p>
          <p>Browser signals. Please note that our Websites do not recognize or respond to browser “do-not-track” requests or other browser privacy signals. However, you can review and update your cookie preferences on our Websites as discussed above.</p>
          <p><br></p>
          <p>7. INTEREST-BASED ADVERTISING</p>
          <p><br></p>
          <p>On some of our Websites, we may work with third-party ad networks, analytics companies, measurement services and others (“third-party ad companies”) to display advertising on our Services and to manage our advertising on third-party sites, mobile apps and online services. We and these third-party ad companies may use cookies, pixels tags and other tools to collect information on our Websites (and on third-party sites and services), such as browsing history, IP address, device ID, cookie and advertising IDs, and other identifiers, general location information and, with your consent, your device’s geolocation information; we and these third-party ad companies use this information to provide you more relevant ads and content and to evaluate the success of such ads and content.</p>
          <p><br></p>
          <p>You can review and manage your preferences for targeting cookies and get information about to targeting cookies in use on one of our Websites by clicking “Cookie Settings” in the footer of our website.</p>
          <p><br></p>
          <p>You may also obtain more information about targeted or “interest-based advertising” and opt-out of many ad networks at the industry websites below:</p>
          <p><br></p>
          <p>Canada: youradchoices.ca</p>
          <p>EU: youronlinechoices.eu</p>
          <p>U.S: youradchoices.com</p>
          <p><br></p>
          <p>8. SOCIAL FEATURES AND THIRD-PARTY PLUG INS</p>
          <p><br></p>
          <p>The Services may integrate with social sharing features and other related tools which let you log in to the Services or share actions you take on the Services. Your use of such features enables the sharing of information with your friends or the public, depending on the settings you establish with the social sharing service. Please refer to the privacy policies of those social sharing services for more information about how they handle the data you provide to or share through them.</p>
          <p><br></p>
          <p><br></p>
          <p>9. INTERNATIONAL TRANSFERS OF PERSONAL INFORMATION</p>
          <p><br></p>
          <p>SIRA is headquartered in the United States and has affiliates and service providers in multiple jurisdictions. As such your personal information may be transferred to the United States and other jurisdictions where we or our service providers have operations, and may be subject to disclosure to the governments, courts, or law enforcement or regulatory agencies of such of these jurisdictions upon transfer. Some of these jurisdictions—including the United States—may not provide the same level of data protection as your home country. In such cases, we rely on suitable personal information transfer safeguards. You may request a copy or information about those safeguards by contacting us as detailed below.</p>
          <p><br></p>
          <p><br></p>
          <p>10. SECURITY</p>
          <p><br></p>
          <p>We use technical, administrative, and physical controls in place to help protect personal information from unauthorized access, use, and disclosure. Even so, despite our efforts, no security measure is ever perfect or impenetrable. In the event that the security of your account has been compromised, please immediately notify us in accordance with Section 16. Contact Us.</p>
          <p><br></p>
          <p><br></p>
          <p>11. DATA RETENTION</p>
          <p><br></p>
          <p>We will retain your personal information for the period necessary to fulfill the purposes outlined in this Privacy Policy unless a longer retention period is required or permitted by law. We may retain personal information for longer where required by our legal and regulatory obligations, professional indemnity obligations, or where we believe it is necessary to establish, defend or protect our legal rights and interests or those of others. With respect to the data and files we handle as a processor, we retain this personal information in accordance with our clients’ instructions.</p>
          <p><br></p>
          <p><br></p>
          <p>12. YOUR RIGHTS AND CHOICES</p>
          <p><br></p>
          <p>We provide several ways for you to exercise certain privacy choices regarding your personal information processed by us, as described in this section. We are committed to respecting the privacy rights of individuals under all privacy laws applicable to us. Some privacy laws require that we provide specific information about individual rights to applicable consumers, which we have set forth in Section 17 of this Privacy Policy. California residents, please review Section 17B. California Residents for information about your California privacy rights.</p>
          <p><br></p>
          <p>Marketing. You may opt out from receiving marketing-related communications from us on a going-forward basis by contacting us or by using the unsubscribe mechanism contained in each email. We will try to comply with your request(s) as soon as reasonably practicable. Please note that if you opt out of receiving marketing-related emails from us, we may still send you important administrative messages, from which you cannot opt out.</p>
          <p><br></p>
          <p>Cookies preferences. To review and update your preferences for most types of cookies (other than those that are necessary) for one of our Websites, Click “Cookie Preferences” link in the footer of that Website.</p>
          <p><br></p>
          <p>Deactivation. You can deactivate your account at any time by contacting us at the email address at the bottom of this page. However, you will not be able to access many of the services to deactivate your account. Please note that even if you request deactivation of your account, it may take some time to fulfill this request.</p>
          <p><br></p>
          <p>Access, amendment and deletion. Under applicable privacy laws, you may have the right to request to review, make amendments, have deleted or otherwise exercise your rights over your personal information that we hold, subject to certain legal limitations and requirements. If you are subject to such a privacy law, you may submit a request to us related to your personal information:</p>
          <p><br></p>
          <p>By submitting an email request to us at dataprivacy@anywhere.re</p>
          <p>By contacting us at (888) 778-6995 (toll free)</p>
          <p>We will process in accordance with and where required by applicable law, and with respect to your personal information that is linked or linkable to the email that you use to send us your request and the verifiable information provided by you as a part of your request. We will take steps to verify your identity before implementing your request.</p>
          <p><br></p>
          <p>Please note that we may need to retain certain information for recordkeeping purposes and/or to complete any transactions that you began prior to requesting a change or deletion. There may also be residual information that will remain within our databases and other records, which will not be removed. We may not always be able to fully address your request, for example if it would impact the duty of confidentiality we owe to others, or if we are legally entitled to deal with the request in a different way.</p>
          <p><br></p>
          <p>13. CHILDREN</p>
          <p><br></p>
          <p>The Services are not intended or directed to children under the age of 16, and we do not knowingly collect any personal information, or knowingly track the use of our Services, from children. If we have actual knowledge that personal information about a child under 16 years old has been collected, then we will take appropriate steps to try and delete such personal information.</p>
          <p><br></p>
          <p><br></p>
          <p>14. THIRD-PARTY WEBSITES</p>
          <p><br></p>
          <p>The Services may contain links to third-party services. We do not own, operate, or control the websites of third parties, including those of independently owned and operated franchisees. Accordingly, this Privacy Policy does not apply to any services maintained or operated by third-parties. When you click on those links, you will go to a third-party website where you will be subject to that service’s privacy policy or similar statement and terms of use, and we encourage you to read that policy statement. We are not responsible for the privacy practices of other services, and we expressly disclaim any liability for their actions, including actions related to the use and disclosure of personal information by those third parties.</p>
          <p><br></p>
          <p><br></p>
          <p>15. CHANGES TO THIS PRIVACY POLICY</p>
          <p><br></p>
          <p>We may amend this Privacy Policy at any time. If we make any material change in how we collect, use, disclose, or otherwise process personal information, we will prominently post a notice regarding such change on the Services. Any material changes to this Privacy Policy will be effective 10 days after our posting of the updated Privacy Policy or as otherwise required by applicable law. Where required to do so by law, we may seek your prior consent to any material changes we make to this Privacy Policy.</p>
          <p><br></p>
          <p><br></p>
          <p>16. CONTACT US</p>
          <p><br></p>
          <p>If you have any questions or concerns about this Notice, please use the following contact information:</p>
          <p><br></p>
          <p>dataprivacy@anywhere.re</p>
          <p>Mailing address: 175 Park Ave., Madison, NJ 07940 Attn: Data Privacy</p>
          <p>Phone number: (888) 778-6995</p>
          <p><br></p>
          <p>17. ADDITIONAL INFORMATION FOR CERTAIN JURISDICTIONS</p>
          <p><br></p>
          <p>If you are a resident of the EEA or the United Kingdom, please review Section 17.A below for additional information about your privacy rights under applicable privacy laws.</p>
          <p><br></p>
          <p>If you are a resident of the United States, please review Section 17.B below for additional information about the categories of personal information we collect about you and your privacy rights under applicable California privacy laws and Section 17.C below for additional information about privacy rights under other U.S. state privacy laws.</p>
          <p><br></p>
          <p><br></p>
          <p>A. EEA and the United Kingdom</p>
          <p><br></p>
          <p>The GDPR and UK data protection laws (where applicable), gives data subjects the following rights regarding their personal information:</p>
          <p><br></p>
          <p>Right of access: You have the right to obtain from us confirmation as to whether or not personal information concerning you is being processed, and where that is the case, to request access to the personal information. The accessed information includes – among others – the purposes of the processing, the categories of personal information concerned, and the recipients or categories of recipient to whom the personal information have been or will be disclosed. You have the right to obtain a copy of the personal information undergoing processing. For further copies requested by you, we may charge a reasonable fee based on administrative costs.</p>
          <p>Right to rectify and complete personal information:you can request the rectification of inaccurate data and the completion of incomplete data. We will inform relevant third parties to whom we have transferred your data about the rectification and completion if we are legally obliged to do so.</p>
          <p>Right to erasure (right to be forgotten): You have the right to obtain from us the erasure of personal information concerning you in limited circumstances where:</p>
          <p>it is no longer needed for the purposes for which it was collected;</p>
          <p>you have withdrawn your consent (where the data processing was based on consent);</p>
          <p>following a successful right to object;</p>
          <p>it has been processed unlawfully; or</p>
          <p>the data has to be erased in order to comply with a legal obligation to which we are subject.</p>
          <p>We are not required to comply with your request to erase personal information if the processing of your personal information is necessary for compliance with a legal obligation, or the establishment, exercise or defense of legal claims.</p>
          <p><br></p>
          <p>Right to restriction of processing: You have the right to obtain from us restriction of processing your personal information. In this case, the respective data will be marked and only be processed by us for certain purposes. This right can only be exercised, subject to certain limitations, where: (i) the accuracy of your personal information is contested; (ii) the processing is unlawful, but you do not want the personal information erased; (iii) it is no longer needed for the purposes for which it was collected, but you still need it to establish, exercise or defend legal claims; or (iv) you have exercised the right to object, and verification of overriding grounds is pending.</p>
          <p>Right to data portability: You have the right to receive the personal information concerning you, which you have provided to us, in a structured, commonly used and machine-readable format and you have the right to transmit those data to another entity without hindrance from us, but in each case only where the processing is (a) based on your consent or on the performance of a contract with you, and (b) also carried out by automated means.</p>
          <p>Right to object: You have the right to object at any time to any processing of your personal information which has our legitimate interests as its legal basis. You may exercise this right without incurring any costs. If you raise an objection, we have an opportunity to demonstrate that we have compelling legitimate interests which override your rights and freedoms. The right to object does not exist, in particular, if the processing of your personal information is necessary to take steps prior to entering into a contract or to perform a contract already concluded.</p>
          <p>Right to withdraw consent: if you have given us your consent for the processing of your personal information, you have the right to withdraw your consent at any time, without affecting the lawfulness of processing based on consent before its withdrawal.</p>
          <p>Right to object to our use of your personal information for direct marketing: You can request that we change the manner in which we contact you for marketing purposes. You can request that we not transfer your personal information to unaffiliated third parties for the purposes of direct marketing or any other purposes.</p>
          <p>Right to obtain a copy of safeguards: you can ask to obtain a copy of, or reference to, the safeguards under which your personal information is transferred outside the EU/EEA. We may redact data transfer agreements to protect commercial terms.</p>
          <p>Right to lodge a complaint with your local supervisory authority: You have a right to lodge a complaint with your local supervisory authority if you have concerns about how we are processing your personal information. We ask that you please attempt to resolve any issue with us first, although you have a right to contact your supervisory authority at any time.</p>
          <p>You may submit your GDPR request to us via:</p>
          <p><br></p>
          <p>Our online webform</p>
          <p>Phone at (888) 778-6995 (toll free)</p>
          <p><br></p>
          <p>B. California Residents.</p>
          <p><br></p>
          <p>This section provides California residents with additional information regarding our collection, use and disclosure of their personal information, as well as their privacy rights, under California privacy laws.</p>
          <p><br></p>
          <p>In this section, we provide additional information to California residents about how we handle their personal information, as required by the California Consumer Privacy Act (“CCPA”). This section does not address or apply to our handling of personal information that is exempt under the CCPA.</p>
          <p><br></p>
          <p>Categories of personal information that we collect and disclose. Our collection, use and disclosure of personal information about a California resident will vary depending upon the circumstances and nature of our interactions or relationship with such resident. Below we describe the categories of personal information we may collect about California residents (including the categories of personal information that we have collected about California residents in the past 12 months):</p>
          <p><br></p>
          <p>Identifiers : includes direct identifiers such as a real name, alias, address, unique personal identifier, online identifier, Internet Protocol (IP) address, email address, account name, social security number, driver’s license number, passport number, or other similar identifiers.</p>
          <p>Customer records : paper and electronic customer records containing personal information, which you provide to us in order to access or use our Services, such as name, digital signature, address, telephone number, email and other contact information, government identifiers, financial and payment information.</p>
          <p>Characteristics of protected classifications : such as disability and health information (e.g., which we may collect in order to, for example, make accommodations available to you at events we host).</p>
          <p>Commercial information : including records of real property, products or services purchased, obtained, or considered, or other purchasing or use histories or tendencies.</p>
          <p>Usage data : internet or other electronic network activity Information including without limitation browsing history, search history, and information regarding a consumer’s interaction with an Internet website, application, or advertisement.</p>
          <p>Location data : location information about a particular individual or device.</p>
          <p>Audio, Video and other Electronic Data : audio, electronic, visual, thermal, olfactory, or similar information such as, CCTV footage and thermal screenings (e.g., collected from visitors to our offices), photographs, and call recordings.</p>
          <p>Employment history: professional or employment-related information.</p>
          <p>Education information : education history and background.</p>
          <p>Profiles and inferences : Inferences drawn from any of the information identified above to create a profile reflecting a resident’s preferences, characteristics, psychological trends, predispositions, behavior, attitudes, intelligence, abilities, or aptitudes.</p>
          <p>Sensitive personal information : in limited circumstances, we may collect certain information considered to be sensitive personal information under the CCPA, including Social Security number and tax ID; driver’s license, state identification card, passport number, or other government identifier; financial account number and payment card data; and geolocation data.</p>
          <p>Categories of third parties. Below we describe the third parties to whom we may disclose each category of personal information for a business or commercial purpose:</p>
          <p><br></p>
          <p>Identifiers:</p>
          <p>service providers</p>
          <p>advisors and agents</p>
          <p>government entities and law enforcement</p>
          <p>affiliates and subsidiaries</p>
          <p>real estate professionals</p>
          <p>advertising networks</p>
          <p>data analytics providers</p>
          <p>social networks</p>
          <p>operating systems and platforms</p>
          <p>Customer records:</p>
          <p>service providers</p>
          <p>advisors and agents</p>
          <p>government entities and law enforcement</p>
          <p>affiliates and subsidiaries</p>
          <p>real estate professionals</p>
          <p>operating systems and platforms</p>
          <p>Characteristics of protected classifications:</p>
          <p>service providers</p>
          <p>advisors and agents</p>
          <p>government entities and law enforcement</p>
          <p>affiliates and subsidiaries</p>
          <p>Commercial information:</p>
          <p>service providers</p>
          <p>advisors and agents</p>
          <p>government entities and law enforcement</p>
          <p>affiliates and subsidiaries</p>
          <p>real estate professionals</p>
          <p>data analytics providers</p>
          <p>social networks</p>
          <p>operating systems and platforms</p>
          <p>Usage data:</p>
          <p>service providers</p>
          <p>advisors and agents</p>
          <p>government entities and law enforcement</p>
          <p>affiliates and subsidiaries</p>
          <p>real estate professionals</p>
          <p>advertising networks</p>
          <p>data analytics providers</p>
          <p>social networks</p>
          <p>operating systems and platforms</p>
          <p>Location data:</p>
          <p>service providers</p>
          <p>advisors and agents</p>
          <p>government entities and law enforcement</p>
          <p>affiliates and subsidiaries</p>
          <p>real estate professionals</p>
          <p>operating systems and platforms</p>
          <p>Audio, video and other electronic data:</p>
          <p>service providers</p>
          <p>advisors and agents</p>
          <p>government entities and law enforcement</p>
          <p>affiliates and subsidiaries</p>
          <p>real estate professionals</p>
          <p>Employment History:</p>
          <p>service providers</p>
          <p>advisors and agents</p>
          <p>government entities and law enforcement</p>
          <p>affiliates and subsidiaries</p>
          <p>real estate professionals</p>
          <p>Education information:</p>
          <p>service providers</p>
          <p>advisors and agents</p>
          <p>government entities and law enforcement</p>
          <p>affiliates and subsidiaries</p>
          <p>real estate professionals</p>
          <p>Profiles and inferences:</p>
          <p>service providers</p>
          <p>advisors and agents</p>
          <p>government entities and law enforcement</p>
          <p>affiliates and subsidiaries</p>
          <p>real estate professionals</p>
          <p>advertising networks</p>
          <p>data analytics providers</p>
          <p>social networks</p>
          <p>Sensitive personal information:</p>
          <p>service providers</p>
          <p>advisors and agents</p>
          <p>government entities and law enforcement</p>
          <p>affiliates and subsidiaries</p>
          <p>real estate professionals</p>
          <p>In addition, we may disclose any of the categories of personal information we collect about you to other third parties as directed by or consented to by you, or where necessary to fulfill your specific requests.</p>
          <p><br></p>
          <p>Sales and sharing. The CCPA defines a “sale” as disclosing or making available personal information to a third-party in exchange for monetary or other valuable consideration, and “sharing” broadly includes disclosing or making available personal information to a third party for purposes of cross-context behavioral advertising. While we do not disclose personal information to third parties in exchange for monetary compensation, we may “sell” or “share” (as defined by the CCPA): (i) identifiers, usage data, customer records, commercial information and profiles with our affiliates and subsidiary companies (e.g., so that they may improve or enhance their own records and for other purposes); and (ii) identifiers, usage data and commercial information to ad networks, social media platforms and data analytics providers (e.g., in order to improve and measure our ad and marketing campaigns).</p>
          <p><br></p>
          <p>Sources of Personal Information. We may collect personal information from the following sources:</p>
          <p><br></p>
          <p>Directly from the individual</p>
          <p>Real estate professionals, including brokers and independent agents of Anywhere brands</p>
          <p>Property records, public records and other publicly available sources</p>
          <p>Advertising networks</p>
          <p>Data analytics providers</p>
          <p>Social networks</p>
          <p>Government entities</p>
          <p>Data brokers</p>
          <p>Affiliate and subsidiary companies</p>
          <p>Service providers</p>
          <p>Purposes of Collection, Use and Disclosure. As described in more detail in Section 3. Use of Personal Information and Section 4. Disclosures of Personal Information, we collect, use, disclose and otherwise process personal information (including sensitive personal information) for the following business or commercial purposes or as otherwise directed or consented to by you:</p>
          <p><br></p>
          <p>Providing support and services</p>
          <p>Analyzing and improving our business</p>
          <p>Personalizing content and experiences</p>
          <p>Advertising, marketing and promotional purposes</p>
          <p>Securing and protecting our business</p>
          <p>Defending our legal rights</p>
          <p>Planning and facilitating business transactions</p>
          <p>Auditing, reporting, corporate governance, and internal operations</p>
          <p>Complying with legal obligations</p>
          <p>Retention. We will retain your personal information for the period necessary to fulfill the purposes outlined in this Privacy Policy or otherwise disclosed to you at the time of collection unless a longer retention period is required or permitted by law. We may retain personal information for longer where required by our legal and regulatory obligations, professional indemnity obligations, or where we believe it is necessary to establish, defend or protect our legal rights and interests or those of others.</p>
          <p><br></p>
          <p>Rights of California Residents. In general, California residents have the following rights under the CCPA:</p>
          <p><br></p>
          <p>California residents’ rights. Under the CCPA, California residents have the following rights (subject to certain limitations) with respect to the personal information we collect and maintain about them:</p>
          <p><br></p>
          <p>Opt out of sales and sharing: to opt-out of our sale and sharing of their personal information.</p>
          <p>Limit uses and disclosures of sensitive personal information : to limit certain uses or disclosures of sensitive personal information to those uses authorized by the CCPA.</p>
          <p>Deletion: to request deletion of their personal information, subject to certain exceptions.</p>
          <p>To know/access : to know what personal information we have collected about them, including the categories of personal information, the categories of sources from which the personal information is collected, the business or commercial purpose for collecting, selling, or sharing personal information, the categories of third parties to whom we disclose personal information, and the specific pieces of personal information we have collected about them.</p>
          <p>Correction : to request correction of inaccurate personal information.</p>
          <p>Non-discrimination : not to be subject to discriminatory treatment for exercising their rights under the CCPA.</p>
          <p>Submitting CCPA Requests. California residents may exercise their CCPA privacy rights to know/access, delate, correct, and limit use/disclosure of sensitive personal information by submitting a verifiable request to us via:</p>
          <p><br></p>
          <p>Our online webform</p>
          <p>Phone at (888) 778-6995 (toll free)</p>
          <p>We will respond to verifiable requests received from California residents as required by law. When you submit a request to know or a request to delete, we will take steps to verify your request by matching the information provided by you with the information we have in our records. You must complete all required fields on our webform (or otherwise provide us with this information via the above toll-free number) so that we can process and verify your request. We will process your request based upon the personal information in our records that is linked or reasonably linkable to the information provided in your request. In some cases, we may request additional information in order to verify your request or where necessary to process your request. If we are unable to adequately verify a request, we will notify the requestor. Authorized agents may initiate a request on behalf of another individual by contacting us via email or phone; authorized agents will be required to provide proof of their authorization and we may also require that the relevant consumer directly verify their identity and the authority of the authorized agent.</p>
          <p><br></p>
          <p>Requests to Opt Out. Our website responds to global privacy control—or “GPC”—signals, which means that if we detect that your browser is communicating a GPC signal, we will process that as a request to opt that particular browser and device out of sales and sharing (i.e., via cookies and tracking tools) on our website. Note that if you come back to our website from a different device or use a different browser on the same device, you will need to opt out (or set GPC for) that browser and device as well. (You can also manage your preferences for targeting and other cookies on one of our Websites through the “Cookie Settings” link in the footer of the Website.) More information about GPC is available at: https://globalprivacycontrol.org/. You may also submit a request to opt out of other sharing and sales by submitting a request via:</p>
          <p><br></p>
          <p>Our online webform</p>
          <p>Phone at (888) 778-6995 (toll free)</p>
          <p>Financial Incentives. We may make available certain programs or offerings that are considered “financial incentives” under the CCPA, and we will provide notice of the material terms of such incentives and obtain consent from individuals before including them in such incentive programs. You can opt out of such programs at any time through the opt out mechanism provided in the program information.</p>
          <p><br></p>
          <p>Rights Under California Shine the Light Law. California residents may request from businesses with whom they have an established business relationship (1) a list of categories of personal information, such as name, address, e-mail address, and the type of services provided to the customer, that a business has disclosed to third parties during the immediately preceding calendar year for the third parties’ direct marketing purposes and (2) the names and addresses of all such third parties. To request the above information please contact us by writing to us under the “Contact Us” section above. We will respond to such requests within 30 days of receipt.</p>
          <p><br></p>
          <p>For more information about our privacy practices, contact us as set forth in the Section 16. above.</p>
          <p><br></p>
          <p><br></p>
          <p>C. Residents of Other U.S. States</p>
          <p><br></p>
          <p>Residents of certain U.S. states (including Virginia) may have additional rights under applicable privacy laws, subject to certain limitations. These rights may include:</p>
          <p><br></p>
          <p>Correction: to correct inaccuracies in their personal information, taking into account the nature and purposes of the processing of the personal information.</p>
          <p>Deletion : to delete their personal information provided to or obtained by us.</p>
          <p>Access: to confirm whether we are processing their personal information and to obtain a copy of their personal information in a portable and, to the extent technically feasible, readily usable format.</p>
          <p>Opt-Out: to opt out of certain types of processing, including:</p>
          <p>to opt out of the “sale” of their personal information.</p>
          <p>to opt out of targeted advertising by us.</p>
          <p>to opt out of any processing of personal information for purposes of making decisions that produce legal or similarly significant effects.</p>
          <p>You may submit a request to exercise your privacy rights via:</p>
          <p><br></p>
          <p>Our online webform</p>
          <p>Phone at (888) 778-6995 (toll free)</p>
          <p>You may also opt out of targeted advertising via on one of our Websites, by clicking the “Cookie Settings” link on our website and turning off targeting (advertising) cookies. (See Section 12. Your Rights and Choices, for additional information about the privacy choices we provide and how to exercise them.) We will respond to your request as required under the applicable privacy law(s). If we deny your request, you may appeal our decision by submitting a request through our webform</p>


      </div></div>
      <div class="row row-1" v-if="$i18n.locale == 'es'" ><div class="col-lg-12 text-justify pt-0 box-1-info" v-html="row.body_es"></div></div>
      
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        name: null,
        lastName: null,
        phone: null,
        email: null,
        comments: null,
      },

      recaptchaVerified:false,

      modal:{
        msg:'',
        icon:'',
        block:false,
      },
      row:{

      }
    }
  },

  methods: {
    goToSection(idx) {
      document.querySelector('#info-6-section').scrollIntoView({behavior: 'smooth'});
    },

    goToFooter(idx) {
      document.querySelector('#footer').scrollIntoView({behavior: 'smooth'});
    },

    makeToast(variant = null, msg, title) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
        toaster: 'b-toaster-top-right',
        appendToast: true
      })
    },

    handleSuccess(response) {
      this.recaptchaVerified = true;
    },
    getRow() { // Obtener la info de la BD
      axios.get(tools.url("/api/privacy")).then((response)=>{
        this.row = response.data;
        
      });
    },
  },
  //insert the following code for vue-meta to work
 metaInfo() {
  return {
    meta: [
      { name: 'og:title', content: this.$root.seoadata.privacy.meta_title},
      { name: 'og:description', content: this.$root.seoadata.privacy.meta_description	},
      { name: 'og:keywords', content: this.$root.seoadata.privacy.meta_keywords},
    ]
  }
},
  mounted(){
      this.getRow();
  }
}
</script>
